import { validateForms } from '../functions/validate-forms.js';

const formCommentSelectors = [
  '.input-comment',
];

const formSelectors = [
  '.promo-form',
  '.popup-form'
];

const checkAgree = [
  {
    selector: ".input-check",
    errorMessage: ' ',
  }
];


// const checkFormInputsComment = [
//   {
//     ruleSelector: '.input-name',
//     rules: [
//       {
//         rule: 'minLength',
//         value: 1,
//         errorMessage: ' ',
//       },
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: ' ',
//       }
//     ]
//   },
//   {
//     ruleSelector: '.input-comment',
//     rules: [
//       {
//         rule: 'minLength',
//         value: 0,
//         errorMessage: ' ',
//       },
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: ' ',
//       }
//     ]
//   },
//   {
//     ruleSelector: '.input-tel',
//     tel: true,
//     telError: ' ',
//     rules: [
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: ' '
//       }
//     ]
//   }
// ];

// function showOkMessage(){
//   const okMessage = document.querySelector('.popup-succesful')
//   okMessage.classList.add('active')
//   const popupForm = document.querySelector('.popup-form');
//   popupForm.classList.remove('active');
// }

const afterForm = () => {
  console.log('Произошла отправка, тут можно писать любые действия');
};

const creditForm = '.credit-form';
const reviewsForm = '.reviews-form';
const popupFormMail = '.popup-formmail';


validateForms(popupFormMail, [
  {
    ruleSelector: '.input-text',
    tel: true,
    telError: ' ',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: ' '
      }
    ]
  }
], [...checkAgree], );

validateForms(reviewsForm, [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 1,
        errorMessage: ' ',
      },
      {
        rule: 'required',
        value: true,
        errorMessage: ' ',
      }
    ]
  },

  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: ' ',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: ' '
      }
    ]
  },
  {
    ruleSelector: '.input-text',
    tel: true,
    telError: ' ',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: ' '
      }
    ]
  }
], [...checkAgree], );

validateForms(creditForm, [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 1,
        errorMessage: ' ',
      },
      {
        rule: 'required',
        value: true,
        errorMessage: ' ',
      }
    ]
  },

  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: ' ',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: ' '
      }
    ]
  }
], [...checkAgree], );

formSelectors.forEach(selector => {
  validateForms(selector, [
    {
      ruleSelector: '.input-name',
      rules: [
        {
          rule: 'minLength',
          value: 1,
          errorMessage: ' ',
        },
        {
          rule: 'required',
          value: true,
          errorMessage: ' ',
        }
      ]
    },
    {
      ruleSelector: '.input-comment',
      rules: [
        {
          rule: 'minLength',
          value: 1,
          errorMessage: ' ',
        },
        {
          rule: 'required',
          value: true,
          errorMessage: ' ',
        }
      ]
    },
    {
      ruleSelector: '.input-summa',
      rules: [
        {
          rule: 'minLength',
          value: 1,
          errorMessage: ' ',
        },
        {
          rule: 'required',
          value: true,
          errorMessage: ' ',
        }
      ]
    },
    {
      ruleSelector: '.input-tel',
      tel: true,
      telError: ' ',
      rules: [
        {
          rule: 'required',
          value: true,
          errorMessage: ' '
        }
      ]
    }
  ], [...checkAgree], );
});
