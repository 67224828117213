import vars from '../_vars.js';

export const enableScroll = () => {
  // Убираем стили, установленные для блокировки прокрутки
  document.body.style.overflow = '';
  document.body.style.paddingRight = '';

  // Восстанавливаем позицию прокрутки
  window.scrollTo({
    top: scrollPosition,
    left: 0,
    behavior: 'auto', // Отключаем анимацию
  });

  // Сбрасываем сохраненную позицию
  scrollPosition = 0;
};
