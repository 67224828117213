import { disableScroll } from '../functions/disable-scroll.js';
import { enableScroll } from '../functions/enable-scroll.js';

let stagePopup = document.querySelector('.stage-popup');
let openStagePopupButtons = document.querySelectorAll('.stage-element__button');

let reviewPopup = document.querySelector('.review-popup');
let openReviewPopupButtons = document.querySelectorAll('.review-block__button');

let formSendPopup = document.querySelector('.formsend-popup');

let formPopup = document.querySelector('.form-popup');
let openFormPopupButtons = document.querySelectorAll('.popup-form-open');


let formMailPopup = document.querySelector('.formmail-popup');
let openFormMailPopupButton = document.querySelector('.credit__form__file-button');

let openPrivacyPopupButtons = document.querySelectorAll('.open-privacy-popup');
let privacyPopup = document.querySelector('.privacy-popup');

let personalPopup = document.querySelector('.personal-popup');
let openPersonalPopupButton = document.querySelector('.open-personal-popup');

openPersonalPopupButton.addEventListener('click', function () {

  personalPopup.classList.add('active');
  disableScroll();

});

addEventListener("click", (e) => {
  if (!e.target.classList.contains("popup-item") && !e.target.closest(".popup-close-button")) return;

  let popup = e.target.closest(".popup-item");
  popup.classList.remove('active');
  enableScroll();
})

openPrivacyPopupButtons.forEach(function (openPrivacyPopupButton) {

  openPrivacyPopupButton.addEventListener('click', function () {

    privacyPopup.classList.add('active');
    disableScroll();

  })

});

openFormPopupButtons.forEach(function (openFormPopupButton) {

  openFormPopupButton.addEventListener('click', function () {

    formPopup.classList.add('active');
    disableScroll();

  })

});

openFormMailPopupButton.addEventListener('click', function () {

  formMailPopup.classList.add('active');
  disableScroll();

})


function addClassesToChildren(containerId, className, count) {
  // Получаем контейнер по его ID
  const container = document.getElementById(containerId);

  if (!container) {
    console.error(`Контейнер с ID '${containerId}' не найден.`);
    return;
  }

  // Получаем всех дочерних элементов контейнера
  const children = container.children;

  // Проверяем, что count не превышает количество дочерних элементов
  if (count > children.length) {
    console.warn(`Количество дочерних элементов меньше, чем ${count}. Будет добавлено ${children.length} классов.`);
    count = children.length;
  }

  // Проходим по каждому дочернему элементу и добавляем класс
  for (let i = 0; i < count; i++) {
    children[i].classList.add(className);
  }
}


openReviewPopupButtons.forEach(function (openReviewPopupButton) {

  openReviewPopupButton.addEventListener('click', function () {

    let reviewContainer = openReviewPopupButton.parentElement;
    let reviewText = reviewContainer.querySelector('.review-block__text').textContent;

    let popupText = reviewPopup.querySelector('.review-popup__text');
    popupText.textContent = reviewText;

    let rate1 = reviewContainer.querySelector('.rate-consultation').textContent;
    let rate2 = reviewContainer.querySelector('.rate-withclient').textContent;
    let rate3 = reviewContainer.querySelector('.rate-credit').textContent;

    let answerManager = reviewContainer.querySelector('.answer-manager').textContent;
    let answerSumma = reviewContainer.querySelector('.answer-summa').textContent;
    let answerReason = reviewContainer.querySelector('.answer-reason').textContent;
    let answerData = reviewContainer.querySelector('.answer-data').textContent;
    let answerText = reviewContainer.querySelector('.answer-text').textContent;

    let popupAnswerManager = reviewPopup.querySelector('.show-answer-manager');
    let popupAnswerSumma = reviewPopup.querySelector('.show-answer-summa');
    let popupAnswerReason = reviewPopup.querySelector('.show-answer-reason');
    let popupAnswerData = reviewPopup.querySelector('.show-answer-data');
    let popupAnswerText = reviewPopup.querySelector('.show-answer-text');

    popupAnswerManager.textContent = answerManager;
    popupAnswerSumma.textContent = answerSumma;
    popupAnswerReason.textContent = answerReason;
    popupAnswerData.textContent = answerData;
    popupAnswerText.textContent = answerText;

    addClassesToChildren('rate1', 'active', rate1);
    addClassesToChildren('rate2', 'active', rate2);
    addClassesToChildren('rate3', 'active', rate3);

    reviewPopup.classList.add('active');
    disableScroll();

  });

});

openStagePopupButtons.forEach(function (openStagePopupButton) {

  openStagePopupButton.addEventListener('click', function () {

    let stageContainer = openStagePopupButton.parentElement;
    let stageTitle = stageContainer.querySelector('.stage-element__title').textContent;
    let stageText = stageContainer.querySelector('.stage-element__text-full').textContent;

    let popupTitle = stagePopup.querySelector('.stage-popup__title');
    let popupText = stagePopup.querySelector('.stage-popup__text');

    popupTitle.textContent = stageTitle;
    popupText.textContent = stageText;

    stagePopup.classList.add('active');
    disableScroll();

  })

});
