  const ranges = document.querySelectorAll('.range-container');

  ranges.forEach(function(rangeContainer) {
      const rangeInput = rangeContainer.querySelector('.custom-range');
      const rangeValue = rangeContainer.querySelector('.range-value');
      const calcSummaNum = document.getElementById('summaCalc')

      // Получение индивидуальных значений из data-атрибутов
      const min = rangeContainer.getAttribute('data-min');
      const max = rangeContainer.getAttribute('data-max');
      const value = rangeContainer.getAttribute('data-value');

      // Установка атрибутов для input[type="range"]
      rangeInput.min = min;
      rangeInput.max = max;
      rangeInput.value = value;

      function formatNumber(number) {
          return new Intl.NumberFormat().format(number);
      }


      function updateRangeValue() {
          const value = rangeInput.value;

          if (rangeContainer.classList.contains('range-summa')) {

              const formattedValue = formatNumber(value);
              rangeValue.value = formattedValue;

          } else if (rangeContainer.classList.contains('range-data')) {

            const value = rangeInput.value; // Значение ползунка
            const number = parseInt(value, 10); // Преобразуем значение в целое число
            rangeValue.value = rangeInput.value;

            if (number % 10 === 1 && number % 100 !== 11) {
                rangeValue.textContent = value + ' год';
            } else if ((number % 10 >= 2 && number % 10 <= 4) && (number % 100 < 12 || number % 100 > 14)) {
                rangeValue.textContent = value + ' года';
            } else {
                rangeValue.textContent = value + ' лет';
            }

          }

          let calcMoney = parseFloat(document.getElementById('calcMoney').value);
          let calcData = parseFloat(document.getElementById('calcData').value);
          let calcSumma = 1;
          let calcPercent = (calcData * 8.9);

          calcSumma = calcMoney + (calcMoney * calcPercent) / 100;
          calcSumma = calcSumma / (calcData * 12)
          calcSumma =  Math.round(calcSumma);
          calcSumma = formatNumber(calcSumma);

          calcSummaNum.textContent = calcSumma;

          // Позиционирование числового значения
          const percent = (value - rangeInput.min) / (rangeInput.max - rangeInput.min) * 100;
          const offset = -rangeValue.offsetWidth / 2;
          rangeValue.style.left = `calc(${percent}% + (${offset}px))`;

          // Обновление трека
          rangeInput.style.background = `linear-gradient(to right, #ffc700 0%, #ffc700 ${percent}%, #ddd ${percent}%, #ddd 100%)`;
      }

      const inputData = document.getElementById('inputData');
      const inputSumma = document.getElementById('inputsumma');

      inputData.addEventListener('change', function() {

          const dataRange = document.getElementById('dataRange');
          let num = parseFloat(inputData.value.replace(/\s+/g, ''))

          if (num < 1) {

              inputData.value = 1; 
              calcData.value = 1;
              updateRangeValue();

          } else if (num > 10) {

              inputData.value = 10;
              calcData.value = 10;
              updateRangeValue();

          } else {

            inputData.value = num;
            calcData.value = num;
            updateRangeValue();

          }
      });


      inputSumma.addEventListener('change', function() {

          const rangeInput = document.getElementById('calcMoney');
          let num = parseFloat(inputSumma.value.replace(/\s+/g, ''))

          if (num < 100000) {

              inputSumma.value = 100000;
              calcMoney.value = 100000;
              updateRangeValue();

          } else if (num > 15000000) {

              inputSumma.value = 15000000;
              calcMoney.value = 15000000;
              updateRangeValue();

          } else {

            inputSumma.value = num;
            calcMoney.value = num;
            updateRangeValue();

          }
      });

      rangeInput.addEventListener('input', updateRangeValue);


      // Инициализация значения при загрузке
      updateRangeValue();
  });
