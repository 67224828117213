import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay]);

const swiperCompaines = new Swiper('.swiper-compaines', {
  loop: true,
  slidesPerView: '1.5',
  speed: 3000,
  autoplay: {
    delay: 1500,
    disableOnInteraction: false,
  },
  breakpoints: {

    720: {
      slidesPerView: '4',
    }
}

});

const swiperDeposit = new Swiper('.swiper-deposit', {

  loop: true,
  slidesPerView: '1.25',
  grid: {
    rows: 1,
    fill: 'row'
  },
  navigation: {
    nextEl: '.navigation-button-next',
    prevEl: '.navigation-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    }
  },
  speed: 1000,

  breakpoints: {

    768: {
      slidesPerView: '2.5',
      grid: {
        rows: 2,
        fill: 'row'
      },
      spaceBetween: 0,
      enabled: false,

    },

    920: {
      slidesPerView: '4',
      grid: {
        rows: 2,
        fill: 'row'
      },
      spaceBetween: 0,
      enabled: false,

    }
}

});


const aboutSwiper = new Swiper('.swiper-about', {
  loop: true,
  slidesPerView: '2',
  spaceBetween: 20,
  speed: 1000,
  autoplay: {
    delay: 1000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.navigation-button-next',
    prevEl: '.navigation-button-prev',
  },
  breakpoints: {

    1150: {
      slidesPerView: '6.5',
    },

    720: {
      slidesPerView: '4.5',
    }
}

});

const stagesSwiper = new Swiper('.swiper-stages', {
  loop: true,
  slidesPerView: '1.5',
  spaceBetween: 20,
  speed: 1500,
  navigation: {
    nextEl: '.navigation-button-next',
    prevEl: '.navigation-button-prev',
  },
  // autoplay: {
  //   delay: 1500,
  //   disableOnInteraction: true,
  // },
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
  breakpoints: {

    920: {
      slidesPerView: '3.5',
    },

    768: {
      slidesPerView: '2.5',
    }
}

});

const stepsSwiper = new Swiper('.swiper-steps', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 1500,
  watchOverflow: false,
  autoplay: {
    delay: 1500,
    disableOnInteraction: true,
  },
  navigation: {
    nextEl: '.navigation-button-next',
    prevEl: '.navigation-button-prev',
  },
  breakpoints: {

    1200: {
      slidesPerView: 4,
      loopedSlides: 1,
      loop: true,
      allowTouchMove: false,
    },

    920: {
      slidesPerView: 3.5,
    },

    768: {
      slidesPerView: 2.5,
    }
}

});

const reviewsSwiper = new Swiper('.swiper-reviews', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  speed: 1500,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
    renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
},
  autoplay: {
    delay: 1500,
    disableOnInteraction: true,
  },
  navigation: {
    nextEl: '.navigation-button-next',
    prevEl: '.navigation-button-prev',
  },

});
