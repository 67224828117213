console.log('components');

import './components/swipers.js';
import './components/d-adaptive.js';
import './components/scroll.js';
import './components/custom-range.js';
import './components/popups.js';
import './components/reviews.js';
import './components/form.js';
import './components/gallery.js';
