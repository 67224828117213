document.addEventListener('DOMContentLoaded', () => {
  const starRatingGroups = document.querySelectorAll('.star-rating');

  starRatingGroups.forEach(starRatingGroup => {
      const stars = starRatingGroup.querySelectorAll('.star');

      stars.forEach(star => {
          star.addEventListener('mouseover', () => {
              const value = star.getAttribute('data-value');
              updateStars(stars, value);
          });

          star.addEventListener('mouseout', () => {
              const selectedValue = starRatingGroup.getAttribute('data-rating');
              updateStars(stars, selectedValue || 0);
          });

          star.addEventListener('click', () => {
              const value = star.getAttribute('data-value');
              setSelectedValue(stars, value);
              starRatingGroup.setAttribute('data-rating', value); // Записываем количество звезд в атрибут
              console.log('Рейтинг:', value); // Выводим рейтинг в консоль
          });
      });
  });

  function updateStars(stars, value) {
      stars.forEach(star => {
          if (parseInt(star.getAttribute('data-value')) <= value) {
              star.classList.add('hovered');
          } else {
              star.classList.remove('hovered');
          }
      });
  }

  function setSelectedValue(stars, value) {
      stars.forEach(star => {
          if (parseInt(star.getAttribute('data-value')) <= value) {
              star.classList.add('selected');
          } else {
              star.classList.remove('selected');
          }
      });
  }
});
