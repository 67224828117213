import vars from '../_vars.js';

window.scrollPosition = 0;

export const disableScroll = () => {
  // Сохраняем текущую позицию прокрутки
  scrollPosition = window.scrollY || document.documentElement.scrollTop;

  // Рассчитываем ширину полосы прокрутки и добавляем отступ к body
  const scrollbarWidth = getScrollbarWidth();
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = `${scrollbarWidth}px`;
};

const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.position = 'absolute';
  outer.style.top = '-9999px';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
